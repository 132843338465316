@import "../variables";
@import "../template/mixin_templates";
@import "../../foundation/functions";

/* Général */
body{
    background-color: $body_background_color;
    color:$texte_color;
}
.content_p, body .wrapper.accueil_diaporama_template{
    background-color: $body_background_color;
}
h2, .h2{
    color:$titre_color;
    border-bottom:$border_titre_color;
}
a{
    color:$texte_lien;
    &:hover{
        color:$texte_lien_hover;
    }
}

/* site en deux colonnes */
.sidebar{
    border:1px solid $colonne_gauche_border;
}

/* Header */

header{
    background-color:$header_background;
}
.slogan{
    color:$slogan_color_texte;
}

/* Menu */
.menu{
    background:$menu_background_color;
    border-top:$menu_border;
    border-bottom:$menu_border;
}
nav#nav-principal{
    background-color:$nav_background_color;
    .navigation_link_p{
        color:$nav_texte_color;
    }
    .navigation_button_p{
        background-color: $li_background_color;
        &.actif, &:hover, &.sousmenu_ouvert{
            background-color:$li_background_color_hover;
            > .navigation_link_p{
                color:$nav_texte_color_hover;
            }
        }
    }
    .sous_navigation_link_p{
        color:$nav_texte_color_sous;
    }
    .sous_navigation_button_p{
        background-color:$li_background_sous_color;
        border-bottom:$border_navbar_li;
        &.actif, &:hover, &.sousmenu_ouvert{
            background-color:$li_background_color_sous_hover;
            > .sous_navigation_link_p{
                color:$nav_texte_color_sous_hover;
            }
        }
    }
    ul.navIcons{
        span.navigation_legend_top,
        span.navigation_legend_top::after{
            background:$li_background_color_hover;
            color:$nav_texte_color_hover;
        }
        li{
            border-left: $menu_border;
            border-right: $menu_border;
        }
    }
}
aside.sidebar{
    background:$menu_background_color;
    nav{
        li {
            background-color: $li_background_color;
            a {
                color: $nav_texte_color;
                border-bottom:$border_navbar_li;
            }
            &:hover{
                background-color:$li_background_color_hover;
                > a{
                    color:$nav_texte_color_hover;
                }
            }
        }
        > ul > li > a{
            border:$border_navbar;
            color: $nav_texte_color;
            &:hover{
                color:$nav_texte_color;
            }
        }
    }
}

/* Produits */

.produits{
    background:$background_produit;
    color:$texte_produits;
    .nomprod a{
        color:$texte_titre_produits;
    }
    .mask{
        a{
            background:$mask_background;
            color:$mask_lien_color;
        }
    }
    a.zoom{
        background:$mask_background;
        color:$mask_lien_color;
        &::before{
            color:#fff !important;
        }
    }
    .remise{
        background:$remise_background_color;
        color:$remise_texte_color;
    }
}

.template_boutique .produits{
    border-bottom:$border_produits;
}

.template_fiche_produit #fiche-produit h2[itemprop="brand"] {
    border-bottom: none;
    &, & a {
        color: #000;
    }
}

.addbasket, .bouton, .button, input[type='submit']{
    border:$border_addbasket;
    background:$background_addbasket;
    color:$color_addbasket;
    &:hover{
        background:$background_addbasket_hover;
        color:$color_addbasket_hover;
    }
}

.produits-accueil::before{
    background-color:$background_produit_accueil;
}

.produit_etiquette,.produit_etiquette2,.produit_etiquette3{
    background:$etiquette_produit_background;
    h3, .h3{
        color:$etiquette_produit_texte;
    }
}
.produit_etiquette::after,.produit_etiquette2::after,.produit_etiquette3::after{
    border-top-color:$etiquette_produit_background;
}

/* Fiche produit */

#fiche-produit{
    .remise-produit{
        background-color:$etiquette_produit_background;
        color:$etiquette_produit_texte;
    }
    .wrap-description h3,
    .wrap-description .h3{
        border:$fiche_produit_border;
    }
    h1, .h1{
        //border:$fiche_produit_border;
    }
    .previews > span{
        background:$btn_background_next !important;
        color:$btn_color_next;
        &::before{
            color:$btn_color_next;
        }
        &:hover{
            background:$btn_background_next_hover !important;
        }
    }
}
.template_fiche_produit_1 #fiche-produit .avec-declinaison .declinaison{
    border: $fiche_produit_border;
}

/* Galerie photo */

.galerie{
    .mask{
        background:$mask_background;
    }
    .addcommentaires, .commentP{
        color:$galerie_texte_color;
    }
    .bloc-titre-galerie p{
        color:$galerie_texte_color;
    }
}

/* Livre d'or */

.template_livreor_1 .message_lo{
    border-color:$message_lo_color;
}

/* Blog / Boutons */

.pagination{
    >li > a{
        background-color:$btn_texte_color;
        color:$btn_background_color;
    }
    li.active a{
        background-color:$btn_background_color;
        border-color:$btn_background_color;
        color:$btn_texte_color;
    }
}

/* Slider */

.accueil_diaporama_template{
    .bx-controls-direction a{
        &.bx-next, &.bx-prev{
            background:$btn_background_next !important;
            color:$btn_color_next;
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            &::before{
                color:$btn_color_next;
            }
            &:hover{
                background:$btn_background_next_hover !important;
            }
        }
    }
    .slide .slide-text{
        background:$background_texte !important;
        &.nocomment .addcommentaires{
            background:$background_texte !important;
        }
        p{
            background:transparent;
            color:$slider_color;
        }
        .addcommentaires{
            background:transparent !important;
            color:$slider_color_link !important;
            &:hover{
                color:$texte_lien_hover !important;
            }
        }
    }
}

/* Footer */

footer{
    background-color:$footer_background_color;
    color: $footer_texte_color;
    border-top:$footer_border-color;
    p{
        color: $footer_texte_color;
    }
    a{
        color:$footer_link_color;
    }
    padding:20px 0;
}

/* Responsive */
@media (max-width: #{$medium-breakpoint-down}) {
    nav#nav-principal>ul ul li{
        background:$li_background_color_hover;
        a:not(.menu_burger){
            color:$nav_texte_color_hover;
        }
    }
    .menu_burger{
        background:$burger_background_color;
        color:$burger_texte_color;
    }
    .dropDown {
        color:inherit;
    }
    li:hover,li.actif{
        > .dropDown {
            color:inherit;
        }
    }
}
@media #{$small-and-down} {


    .accueil_diaporama_template{
        .slide .slide-text{
            background:transparent !important;
            p{
                background:$background_texte !important;
                color:$slider_color;
            }
            .addcommentaires{
                background:$background_texte !important;
                color:$slider_color_link !important;
                &:hover{
                    color:$texte_lien_hover !important;
                }
            }
        }
    }
}

/* Template Panier */

.template-panier{
    background-color:$panier-background-color;
    color:$panier-titre-color;
    .connexion p{
        border-color:$panier-titre-color;
        &, a{
            color:$panier-titre-color;
        }
    }
    #header-panier{
        color:$panier-titre-color;
    }
    #panier #paniercontent{
        background-color:$panier-content-background-color;
        &, #paniertable{
            color:$panier-texte-color;
        }
        border-color:$panier-background-color;
    }
    &.template-panier-1{
        #panier #paniercontent{
            &, #paniertable{
                color:$panier-titre-color;
            }
        }
    }
    .count-live{
        background:$panier-content-background-color;
        color:$panier-texte-color;
    }
}

.apercu-article{
    h2.titre-article, .h2.titre-article{
        background-color: $body_background_color;
    }
}


.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
    background: $couleur_secondaire;
    border-color: $couleur_secondaire;

    a {
        color: #fff;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
    border-color: $couleur_secondaire;
    color: $couleur_secondaire;

    a, span {
        color: $couleur_secondaire;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
    color: #000;
}